.footer{
    margin-top: 5%;
    background-image: url('../../assets/images/footer-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 0px 150px 0px #fdf7f2;

    .top{
        width: 80%;
        margin: 0 auto;
        display: flex;
        gap: 5%;

        @media screen and (max-width: 770px) {
            flex-direction: column;
        }

        .usefullinks{
            // min-width: max-content;

            @media screen and (max-width: 770px) {
                width: 70%;
                margin-top: 30px;
            }

            h2{
                min-width: max-content;
            }
            ul{
                list-style: none;

                li{
                    position: relative;
                    right: 2rem;
                    margin: 25px 0;
                    min-width: max-content;

                    a{
                        text-decoration: none;
                        color: gray;

                        &:hover{
                            color: blue;
                        }
                    }
                }
            }
            img{
                width: 90px;
                height: 90px;
                border-radius: 50px;
            }
            p{
                margin: 10px 0;
                color: gray;
            }
            a{
                text-decoration: none;
            }
        }
    }
    hr{
        margin: 30px 30px;
        opacity: 0.5;
    }
    .contents{
        display: flex;
        justify-content: space-between;
        padding-bottom: 2%;
        padding-top: 2%;

        @media screen and (max-width: 770px) {
            flex-direction: column;
        }

        a{
            text-decoration: none;
            color: gray;

            &:hover{
                color: blue;
            }
        }
        p{
            margin-left: 35px;
            opacity: 0.7;

            @media screen and (max-width: 770px) {
                margin: 0 auto;
                margin-bottom: 10px;
            }
        }
        ul{
            display: flex;
            margin-right: 35px;

            li{
                list-style-type: none;
                margin-left: 10px;

                @media screen and (max-width: 770px) {
                    margin: 10px auto;
                }

                img{
                    margin-left: 5px;

                    @media screen and (max-width: 770px) {
                        margin-left: 0;
                    }

                    // &:hover{
                    //     box-shadow: 0px 0px 20px 0px rgba(128, 126, 126, 0.47);
                    // }
                }
            }
        }
        @media screen and (max-width: 500px) {
            a{
                text-align: center;
                font-size: small;
            }
        }
        @media screen and (max-width: 820px) {
            a{
                text-align: center;
                font-size: small;
            }
        }
    }
}