body {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

// .home {
//   overflow: hidden;
//   padding: 0;
//   margin: 0;
//   background-image: linear-gradient(to bottom, transparent, #81ad10),
//     url("../../assets/images/bg-image.webp");
//   background-size: cover;
//   background-position: center center;
//   height: 100vh;
//   background-repeat: no-repeat;
//   text-align: center;
//   position: relative;

//   @media screen and (max-width: 500px) {
//     height: 60vh;
//   }
//   @media screen and (max-width: 820px) {
//     height: 60vh;
//   }
//   .shape1 {
//     position: absolute;
//     left: 5rem;
//   }
//   .shape2 {
//     position: absolute;
//     right: 5rem;
//   }
//   .shape3 {
//     position: absolute;
//     left: 15rem;
//     top: 25rem;
//   }
//   .shape4 {
//     position: absolute;
//     top: 6rem;
//   }
//   .shape5 {
//     position: absolute;
//     top: 25rem;
//     right: 10rem;
//   }
//   section {
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     @media screen and (max-width: 500px) {
//       display: block;
//       padding: 20px;
//       height: 500px;
//       //   margin-top: 100px;
//       //   flex-direction: column;
//       //   margin-left: 1rem;
//     }
//     @media screen and (max-width: 726px) {
//       margin-left: 1.5rem;
//     }
//     @media screen and (max-width: 1024px) {
//       margin-left: 2rem;
//     }
//   }

//   .leftSection {
//     text-align: left;
//     height: 100vh;
//     position: relative;
//     top: 50%;
//     top: 10%;
//     margin: 0 auto;
//     // background-image: url('../../assets/icons/shape-008.png');
//     background-repeat: no-repeat;

//     @media screen and (max-width: 500px) {
//       width: 100%;
//       text-align: center;
//       height: 40vh;
//       margin: auto 0;
//       //   margin-left: 1rem;
//     }

//     @media screen and (max-width: 928px) {
//       margin-left: 175px;
//     }
//     @media screen and (max-width: 480px) {
//       margin-left: 70px;
//     }

//     .p1 {
//       background-color: white;
//       padding: 7px 5px;
//       width: 25%;
//       text-align: center;
//       border-radius: 20px;
//       //   margin: 0 auto;
//       //   font-size: 0.9rem;
//       font-weight: bold;

//       @media screen and (max-width: 1046px) {
//         width: 100%;
//       }
//       @media screen and (max-width: 480px) {
//         width: 50%;
//         // min-width: max-content;
//       }
//     }
//     h1 {
//       font-size: 4.5rem;
//       width: 100%;
//       margin: 50px 0;
//       text-align: left;
//       line-height: 50px;

//       @media screen and (max-width: 1046px) {
//         font-size: 3rem;
//       }
//       @media screen and (max-width: 500px) {
//         font-size: 2rem;
//         margin: 25px 0;
//         text-align: center;
//         // min-width: max-content;
//       }
//     }
//     p {
//       //   width: 40%;
//       //   margin: 20px auto;
//       margin: 50px 0;
//       font-size: 1.7rem;
//       text-align: left;

//       @media screen and (max-width: 1046px) {
//         // width: 70%;
//       }
//       @media screen and (max-width: 500px) {
//         // width: 75%;
//         font-size: 1rem;
//         text-align: center;
//         margin: 25px 0;
//       }
//     }
//     .link {
//       text-align: left;
//       margin-top: 70px;

//       .demo {
//         background-color: blue;
//         color: white;
//         font-size: 26px;
//         text-decoration: none;
//         padding: 15px 35px;
//         border-radius: 30px;
//         font-weight: bold;
//         transition: all 0.5s ease;
//         cursor: pointer;

//         &:hover {
//           background-color: darkblue;
//         }

//         @media screen and (max-width: 500px) {
//           font-size: 16px;
//           text-align: center;
//           margin: 25px auto;
//         }
//       }

//       @media screen and (max-width: 500px) {
//         margin-top: 50px;
//         text-align: center;
//       }
//     }
//   }
//   .rightSection {
//     padding-right: 8rem;
//     @media screen and (max-width: 500px) {
//       display: none;
//       margin-left: 1rem;
//     }
//   }
//   .livechat {
//     position: fixed;
//     right: 2rem;
//     bottom: 2rem;
//     z-index: 99999;
//     cursor: pointer;
//   }
//   @media screen and (max-width: 500px) {
//     .shape1 {
//       display: none;
//     }
//     .shape2 {
//       display: none;
//     }
//     .shape3 {
//       position: absolute;
//       left: 10rem;
//       top: 18rem;
//     }
//     .shape4 {
//       position: absolute;
//       top: 10rem;
//     }
//     .shape5 {
//       position: absolute;
//       top: 15rem;
//       right: 10rem;
//     }
//     // .p1 {
//     //   min-width: max-content;
//     //   padding: 7px 8px;
//     // }
//   }
//   @media screen and (max-width: 820px) {
//     .shape1 {
//       display: none;
//     }
//     .shape2 {
//       display: none;
//     }
//     .shape3 {
//       position: absolute;
//       left: 10rem;
//       top: 18rem;
//     }
//     .shape4 {
//       position: absolute;
//       top: 10rem;
//     }
//     .shape5 {
//       position: absolute;
//       top: 15rem;
//       right: 10rem;
//     }
//     // .p1 {
//     //   min-width: max-content;
//     //   padding: 7px 8px;
//     // }
//   }
// }
.middle {
  margin-bottom: 30px;
  padding-top: 200px;

  .transactions {
    display: flex;
    justify-content: space-between;
    background-color: #0e2038;
    color: white;
    text-align: center;
    padding: 3rem 6rem;
    align-items: center;
    width: 80%;
    margin: 4rem auto;
    margin-bottom: 0rem;
    border-radius: 10px;

    @media screen and (max-width: 820px) {
      width: 70%;
      padding: 0 6rem;
      justify-content: center;
    }
    @media screen and (max-width: 500px) {
      flex-direction: column;
      padding: 3rem 0rem;
    }

    .transaction {
      margin: 1rem;

      img,
      .trans-icon {
        width: 125px;
        height: 125px;
        font-size: 10px;
      }
      span {
        display: block;
        color: lightcoral;
        font-size: 2rem;
        margin: 0.5rem 0;
      }
      p {
        color: rgb(202, 200, 200);
        // font-size: 1.2rem;
      }
    }

    @media screen and (max-width: 820px) {
      .transaction {
        margin: 1rem;

        img,
        .trans-icon {
          width: 100px;
          height: 100px;
          font-size: 5px;
        }
        span {
          display: block;
          color: lightcoral;
          font-size: 1rem;
          margin: 0.5rem 0;
        }
        p {
          color: rgb(202, 200, 200);
          // font-size: 1.2rem;
        }
      }
    }
  }

  .image {
    display: flex;
    justify-content: center;
    margin: 2%;
    margin-bottom: -3%;

    img {
      border-radius: 10px;
      box-shadow: 0px 0px 150px 0px rgba(197, 220, 231, 0.47);
    }
  }
  .dashboard {
    // border-radius: 20px;
    // border: 20% solid rgb(200, 230, 240);
    width: 99%;

    @media screen and (max-width: 1046px) {
    }
  }
  .p2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: 550;

    @media screen and (max-width: 480px) {
      font-size: 1.8rem;
      width: 90%;
      margin: 0 auto;
    }
  }
  .teams {
    margin: 0 auto;
    width: 60%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
      // width: 100%;
    }
    img {
      width: 100px;
      height: 100px;
      opacity: 0.5;
      border-radius: 50px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        margin: 3%;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
@media screen and (max-width: 926px) {
  .middle {
    padding-top: 50px;
  }
}
hr {
  margin: 0 5%;
}
.essential {
  // margin-top: 80px;
  text-align: center;
  background-color: #fff;
  padding: 2% 100px 0 100px;

  //   h2 {
  //     font-size: 3rem;
  //     width: 50%;h2 {
  //     font-size: 3rem;
  //     width: 50%;
  //     margin: 30px auto;

  //     @media screen and (max-width: 1046px) {
  //       width: 70%;
  //     }
  //     @media screen and (max-width: 480px) {
  //       font-size: 1.5rem;
  //     }
  //   }
  //     margin: 30px auto;

  //     @media screen and (max-width: 1046px) {
  //       width: 70%;
  //     }
  //     @media screen and (max-width: 480px) {
  //       font-size: 1.5rem;
  //     }
  //   }
  // .essentials {
  //   // display: flex;
  //   // // flex-wrap: wrap;
  //   // justify-content: space-between;
  //   // width: 90%;
  //   // margin: 0 auto;
  //   // gap: 10px;
  //   // margin-top: 40px;
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 30px;
  //   // width: 85%;
  //   margin: 3% auto;
  //   justify-content: center;
  //   padding: 0px 110px 110px 100px;

  //   // display: grid;
  //   // padding-bottom: 3rem;

  //   // .feature {
  //   //   background-color: white;
  //   //   padding: 10px;
  //   //   width: 30%;

  //   //   h1 {
  //   //     font-weight: lighter;
  //   //   }
  //   //   img {
  //   //     margin: auto auto;
  //   //   }
  //   //   .imgg {
  //   //     margin: 1.3rem 0;
  //   //   }

  //   //   @media screen and (max-width: 1046px) {
  //   //     width: 85%;

  //   //     h1 {
  //   //       font-size: 1.5rem;
  //   //     }
  //   //   }

  //   //   h3 {
  //   //     color: #275067;
  //   //     font-weight: bold;
  //   //     font-size: 1.3rem;
  //   //   }
  //   //   @media screen and (max-width: 500px) {
  //   //     img,
  //   //     .imgg {
  //   //       margin-left: -1.5rem;
  //   //     }
  //   //   }
  //   // }

  //   // h3 {
  //   //   margin: 20px 0;
  //   // }
  //   // p {
  //   //   opacity: 0.9;
  //   // }
  //   // img {
  //   //   border-radius: 10px;
  //   // }
  // }
  // @media screen and (max-width: 1047px) {
  //   .essentials {
  //     // padding: 0px 80px;
  //     margin: 0 auto;
  //   }
  // }
  // @media screen and (max-width: 1046px) {
  //   .essentials {
  //     width: 100%;
  //     padding: 0px 100px;

  //     .card {
  //       width: calc(100% / 2);
  //     }
  //   }
  // }
  // @media screen and (max-width: 820px) {
  //   .essentials {
  //     width: 90%;
  //     flex-wrap: wrap;
  //     flex-direction: column;
  //     padding: 0px;

  //     .card {
  //       width: 80%;
  //       margin: 0 auto;
  //     }
  //   }
  // }
  // @media screen and (max-width: 500px) {
  //   .essentials {
  //     flex-wrap: wrap;
  //     flex-direction: column;
  //   }
  // }
  .essentials {
    width: 100%;
    margin: 70px 0px;

    display: grid;
    grid-gap: 50px;
  }
  /* Styles for extra large screens (3 columns) */
  @media screen and (min-width: 1200px) {
    .essentials {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .card {
      width: 95%;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .essentials {
      grid-template-columns: 1fr 1fr;
    }
    .card {
      width: 90%;
    }
  }

  @media screen and (max-width: 767px) {
    .essentials {
      grid-template-columns: 1fr;
    }
    .card {
      width: 90%;
    }
  }
}

.section2 {
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 1.5rem;
  gap: 30px;

  @media screen and (max-width: 1046px) {
    flex-direction: column;
  }

  aside {
    @media screen and (max-width: 1046px) {
      margin-top: -6%;
      text-align: center;
    }

    img {
      // border-radius: 50px;

      @media screen and (max-width: 1046px) {
        display: none;
      }
    }
  }
  img {
    border-radius: 10px;

    @media screen and (max-width: 1046px) {
      width: 90%;
      height: 90%;
      margin: 0 auto;
      border-radius: 7px;
    }
  }
  .img-benefit {
    width: 580px;

    @media screen and (max-width: 500px) {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
    @media screen and (max-width: 820px) {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }
  h3 {
    font-size: 3rem;
    margin: 17px 0;

    @media screen and (max-width: 1046px) {
      font-size: 2.6rem;
      // min-width: max-content;
      margin: 14px 0;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.8rem;
    }
  }
  p {
    opacity: 0.7;
    margin-bottom: 40px;
  }
  a {
    background-color: green;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.5s ease;

    &:hover {
      background-color: rgb(5, 73, 5);
    }
  }
}
.goals {
  @media screen and (max-width: 1046px) {
    flex-direction: column;
  }
}
.connect {
  text-align: center;
  margin-bottom: 5%;
  background-image: url("../../assets/images/bg-image.webp");
  box-shadow: 0px 0px 150px 0px rgba(243, 222, 208, 0.47);
  width: 100%;
  margin: 0 auto;

  h1 {
    font-size: 4rem;
    width: 60%;
    margin: 0 auto;

    @media screen and (max-width: 1046px) {
      width: 90%;
      font-size: 2.8rem;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.8rem;
    }
  }
  .apps {
    width: 60%;
    margin: 2% auto;
  }
}
.businesplan {
  // .plan{
  //     border: 1px solid rgb(187, 182, 182);
  //     border-radius: 10px;
  //     padding: 20px;
  //     padding-bottom: 40px;
  //     background-color: rgb(241, 245, 247);
  //     width: 100%;
  //     margin: 0 auto;

  //     @media screen and (max-width: 1046px) {
  //         width: 75%;
  //         margin-top: 0.5rem;
  //     }

  //     h3{
  //         margin: 20px 0;
  //     }
  //     p{
  //         margin: 20px 0;
  //     }
  //     span{
  //         margin: 30px 0;
  //         color: blue;
  //         font-weight: bold;
  //         font-size: 3.5rem;
  //     }
  //     ul{
  //         margin: 30px 0;
  //         list-style: none;

  //         li{
  //             &::before{
  //                 content: '✓';
  //             }
  //             // list-style-type: '\2713';
  //             margin: 10px 0;
  //             position: relative;
  //             right: 2rem;

  //             @media screen and (max-width: 1046px) {

  //             }
  //         }
  //     }
  //     a{
  //         background-color: blue;
  //         padding: 10px 20px;
  //         color: white;
  //         text-decoration: none;
  //         border-radius: 30px;
  //         font-weight: bold;
  //         // position: absolute;
  //         // bottom: 1rem;

  //         &:hover{
  //             background-color: darkblue;
  //         }
  //     }
  // }

  h1 {
    font-size: 3.5rem;
    width: 60%;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 1046px) {
      font-size: 2.6rem;
      width: 80%;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.8rem;
    }
  }

  .plans-container {
    display: flex;
    flex-direction: column;
    padding: 40px;

    .business1 {
      border: 1px solid rgb(187, 182, 182);
      border-radius: 10px;
      align-items: center;
      padding: 40px 20px;
      background-color: rgb(241, 245, 247);
      width: 90%;
      margin: 0 auto;
      text-align: center;
      margin-top: -2.3rem;

      @media screen and (max-width: 1046px) {
        width: 80%;
        margin-top: 0rem;
      }

      h3 {
        margin: 20px 0;
      }
      p {
        margin: 20px 0;
      }
      .a {
        background-color: green;
        padding: 10px 20px;
        color: white;
        text-decoration: none;
        border-radius: 30px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          background-color: rgb(5, 73, 5);
        }
      }
    }

    .plans {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      // width: 85%;
      margin: 50px auto 70px auto;
      justify-content: center;

      @media screen and (max-width: 1046px) {
        flex-direction: column;
      }

      .plan {
        border: 1px solid rgb(187, 182, 182);
        border-radius: 10px;
        padding: 20px;
        padding-bottom: 40px;
        background-color: rgb(241, 245, 247);
        width: 28%;
        position: relative;

        @media screen and (max-width: 1046px) {
          width: 80%;
          margin: 0.1rem auto;
        }

        h3 {
          margin: 20px 0;
        }
        p {
          margin: 20px 0;
        }
        span {
          margin: 30px 0;
          color: blue;
          font-weight: bold;
          font-size: 3.5rem;
        }
        ul {
          margin: 10px 0 50px 0;
          list-style: none;

          li {
            &::before {
              content: "✓";
            }
            // list-style-type: '\2713';
            margin: 10px 0;
            position: relative;
            right: 2rem;

            @media screen and (max-width: 1046px) {
            }
          }
        }
        a {
          position: absolute;

          bottom: 25px;
          background-color: green;
          padding: 10px 20px;
          margin-top: 25px;
          color: white;
          text-decoration: none;
          border-radius: 30px;
          font-weight: bold;
          transition: all 0.5s ease;

          &:hover {
            background-color: rgb(5, 73, 5);
          }
        }
      }
    }
  }
}
.quest {
  h1 {
    text-align: center;
    font-size: 3rem;
    margin: 3% auto;

    @media screen and (max-width: 1046px) {
      font-size: 2.5rem;
      margin-top: 7%;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.8rem;
    }
  }
  .questions {
    // width: 90%;
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 4%;

    @media screen and (max-width: 1046px) {
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
    }

    .aside2 {
      max-width: 40%;

      @media screen and (max-width: 1060px) {
        max-width: none;
      }

      @media screen and (max-width: 500px) {
        max-width: none;
      }
      @media screen and (max-width: 820px) {
        max-width: none;
      }
    }
    aside {
      max-width: 40%;
      @media screen and (max-width: 1060px) {
        max-width: none;
      }

      @media screen and (max-width: 500px) {
        max-width: none;
      }
      @media screen and (max-width: 820px) {
        max-width: none;
      }

      .question {
        border: 1px solid rgb(219, 214, 214);
        margin: 2% 0;
        // padding: 3%;
        padding: 10px 30px;
        border-radius: 10px;

        h4 {
          margin-bottom: 1%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .hOpen1 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose1 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen2 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose2 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen3 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose3 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen4 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose4 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen5 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose5 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen6 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose6 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen7 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose7 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen8 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose8 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .hOpen9 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
            color: blue;
          }
          .hClose9 {
            text-decoration: none;
            color: black;
            opacity: 0.8;
            margin: 15px 0;
          }
          .expnicon {
            cursor: pointer;
          }
        }
        .paraOpen1 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose1 {
          display: none;
        }
        .paraOpen2 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose2 {
          display: none;
        }
        .paraOpen3 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose3 {
          display: none;
        }
        .paraOpen4 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose4 {
          display: none;
        }
        .paraOpen5 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose5 {
          display: none;
        }
        .paraOpen6 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose6 {
          display: none;
        }
        .paraOpen7 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose7 {
          display: none;
        }
        .paraOpen8 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose8 {
          display: none;
        }
        .paraOpen9 {
          display: block;
          padding-bottom: 10px;
        }
        .paraClose9 {
          display: none;
        }
      }
    }
  }
}
.meetings {
  // background-color: rgb(255, 80, 80);
  width: 90%;
  margin: 3% auto;
  border-radius: 15px;
  padding-bottom: 40px;
  height: 250px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-image: url("../../assets/images/cta.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  h1 {
    font-size: 3rem;
    color: white;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 5%;

    @media screen and (max-width: 1046px) {
      font-size: 2.5rem;
      width: 90%;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.7rem;
    }
  }
  .demo {
    background-color: green;
    color: white;
    text-decoration: none;
    padding: 15px 35px;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      background-color: rgb(5, 73, 5);
    }

    @media screen and (max-width: 500px) {
      padding: 10px 20px;
    }
  }
}
.testimonials {
  text-align: center;

  h2 {
    margin: 1.5rem;
    font-size: 2.5rem;
    font-weight: lighter;
  }
  .people {
    display: flex;
    justify-content: space-between;
    width: 91%;
    gap: calc(9% / 3);
    margin: 50px auto;

    @media screen and (max-width: 820px) {
      flex-direction: column;
      gap: 30px;
    }
    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    .person-content-wrapper {
      padding: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 6px;

      .person {
        // margin-left: 30px;
        margin: 1rem;

        img {
          width: 200px;
          height: 200px;
          border-radius: 100px;
        }

        h3 {
          margin: 1rem;
        }
      }
    }
  }
}
.contact-form {
  text-align: center;
  background-image: linear-gradient(to right, transparent, #000),
    url("../../assets/images/contact-us.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 2rem;

  h2 {
    color: white;
    padding: 1.5rem;
    font-size: 2.5rem;
    font-weight: lighter;
  }

  form {
    max-width: 740px;
    margin: 0 auto;
    padding: 40px;

    .message {
      background: green;
      color: #fff;
      padding: 20px;
      width: 100%;
    }
    .showMsg {
      display: block;
    }
    .hideMsg {
      display: none;
    }

    .nname {
      width: 100%;
      display: flex;
      margin: 0 auto;
      gap: 10px;
    }
    span {
      width: 100%;
      display: inline-block;
      text-align: left;
      color: red;
    }
    input {
      padding: 1.3rem 0.8rem;
    }
    textarea {
      padding: 0.8rem;
    }

    input,
    textarea {
      margin-top: 0.5rem;
      border: none;
      outline: none;
      border-radius: 5px;
      width: 100%;

      &::placeholder {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .form-control {
      width: 100%;

      input,
      textarea {
        width: calc(100% - 25px);
      }
    }
  }

  .send {
    width: 100%;

    button {
      background-color: blueviolet;
      color: #fff;
      width: 100%;
      margin-top: 20px;
      padding: 0.8rem;
      border: none;
      outline: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: larger;
      font-weight: 600;
    }

    button:hover {
      background: rgb(9, 9, 233);
    }
  }
  @media screen and (max-width: 500px) {
    // h2 {
    //   font-size: 2rem;
    // }
    // .nname {
    //   width: 100%;
    // }
    // input,
    // textarea {
    //   width: 100%;
    //   // margin: 0.2rem;
    // }
    // .send {
    //   button {
    //     width: 100%;
    //   }
    // }
  }
}
hr {
  margin-top: 2rem;
}
