.feature-container {
  width: 100%;
  // height: 250px;
  position: absolute;
  bottom: -16%;
  left: 0;
  // padding: 22px;

  .inner-wrapper {
    width: 80%;
    height: 100%;
    margin: 80px auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 30px rgba($color: #000, $alpha: 0.1);

    .row {
      width: 100%;
      height: 100%;
      display: flex;

      .box:not(:last-child) {
        border-right: 1px solid #eee;
      }

      .box {
        width: 100%;
        height: 100%;
        padding: 20px;
        text-align: center;

        .icon-wrapper {
          padding-bottom: 10px;
        }

        h4 {
          font-size: 18px;
          font-weight: 700;
          text-transform: capitalize;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }

        p {
          color: #838181;
          margin-bottom: 5px;
        }

        .link {
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          color: #0e2038;
        }
        .link:hover {
          color: #ff7f50;
        }
      }
      @media screen and (max-width: 1024px) and (min-width: 926px) {
        .box {
          padding: 16px;
        }
        .icon-wrapper {
          padding-bottom: 10px !important;

          img {
            width: 50px !important;
          }
        }
        h4 {
          font-size: 16px !important;
        }

        p {
          font-size: smaller;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .inner-wrapper {
      width: 95%;
    }
  }
}
@media screen and (min-width: 1040px) and (max-width: 1240px) and (min-height: 804px) {
  .feature-container {
    bottom: -20%;
  }
}

@media screen and (max-width: 1124px) and (min-width: 1025px) and (max-height: 800px) {
  .feature-container {
    bottom: -39%;
  }
}
@media screen and (max-width: 1300px) and (max-height: 800px) {
  .feature-container {
    bottom: -30%;
  }
}
@media screen and (max-width: 926px) {
  .feature-container {
    display: none;
  }
}
