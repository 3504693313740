*{
    margin: 0;
}

.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(201, 201, 201, 0.47);
    margin: 0;
    // padding-left: 2.5rem;
    // padding-right: 2.5rem;
    position: fixed;
    width: 100%;
    z-index: 1;
    transition: all 0.5s ease-in-out;

    img{
        margin: 4px 0px 4px 2.5rem;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        padding: 0.1rem;
    }
    .ul{
        // display: inline-block;

        position: fixed;
        inset: 0 0 0 0;
        width: 200px;
        // background: hsl(0, 0%, 70);
        background-color: rgb(192, 185, 185);
        backdrop-filter: blur(1rem);
        display: inline-flex;
        flex-direction: column;
        gap: 0.5rem;
        transition: all 0.5s ease;
        // margin-left: -100%;
        // transition: all 0.5s ease;

        @media screen and (max-width: 1046px) {
            position: fixed;
            inset: 0 0 0 0;
            width: 200px;
            float: right;
            // background: hsl(0, 0%, 70);
            background-color: rgb(192, 185, 185);
            backdrop-filter: blur(1rem);
            display: inline-flex;
            flex-direction: column;
            gap: 0.5rem;
            transition: all 0.5s ease;
            // display: none;
        }
        @media screen and (min-width: 1046px) {
            display: inline-block;
        }

        .logo{
            // display: none;
            
            img{
                width: 100px;
                height: 100px;
                border-radius: 50px;
                margin: 0.5rem auto;
                margin-top: 1.5rem;
            }
            @media screen and (max-width: 1046px) {
                display: block;
            }
        }
        .menus{
            list-style-type: none;
            position: relative;
            cursor: pointer;
            float: left;
            width: 100px;
            height: 50px;
            line-height: 50px;

            @media screen and (max-width: 1046px) {
                
            }
            .icons{
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 1046px) {
                    float: left;
                }

                span{
                    min-width: max-content;
                }

                .icon{
                    font-size: larger;
                }
            }

            &:hover{
                color: blue;

                ol{
                    display: block;

                    @media screen and (max-width: 1046px) {
                        z-index: 1;
                    }
                }
            }

            ol{
                position: absolute;
                top: 100%;
                left: -20%;
                width: 200%;
                list-style: none;
                padding: 0;
                display: none;
                background-color: white;
                box-shadow: 0px 0px 10px 5px rgba(201, 201, 201, 0.47);
                padding: 5px 20px;
                border-radius: 10px;
                color: black;

                @media screen and (max-width: 1046px) {
                    width: max-content;
                }
                
                li{
                    min-width: 100%;

                    &:hover{
                        color: blue;
                        perspective: 1000px;;
                    }
                }
            }

            select{
                border: none;
                font-size: 1rem;
            }
            
            a{
                text-decoration: none;
            }
        }
    }
    .ull{
        position: fixed;
        inset: 0 0 0 0;
        width: 300px;
        background-color: white;
        backdrop-filter: blur(1rem);
        display: inline-flex;
        flex-direction: column;
        gap: 0.5rem;
        transition: all 0.5s ease;
        margin-left: -100%;
        border-right: 2px solid black;
        border-top: 2px solid black;

        .ullimage{
            padding: 0.5rem 0;
            img{
                width: 105px;
                height: 105px;
            }
        }
        li{
            list-style: none;
            font-size: 1.2rem;
            // margin-bottom: .5rem;
            text-align: left;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            margin-left: -2.5rem;
            padding: 1rem;

            a{
                text-decoration: none;
                color: black;
                display: block;
            }
        }
    }
    .ullc{
        position: fixed;
        inset: 0 0 0 0;
        width: 300px;
        background-color: white;
        backdrop-filter: blur(1rem);
        display: inline-flex;
        flex-direction: column;
        gap: 0.5rem;
        transition: all 0.5s ease;
        // margin-left: -100%;
        border-right: 2px solid rgb(104, 103, 103);
        border-top: 2px solid rgb(104, 103, 103);

        .ullimage{
            padding: 0.5rem 0;
            img{
                width: 165px;
                height: 165px;
                border-radius: 50%;
            }
        }
        li{
            list-style: none;
            font-size: 1.2rem;
            // margin-bottom: .5rem;
            text-align: left;
            border-top: 1px solid rgb(104, 103, 103);
            border-bottom: 1px solid rgb(104, 103, 103);
            margin-left: -2.5rem;
            padding: 1rem;

            &:hover{
                background-color: rgb(216, 216, 216);
                cursor: pointer;
            }

            a{
                text-decoration: none;
                color: black;
                display: block;
            }
        }
    }
    .ulclose{
        position: fixed;
        inset: 0 0 0 0;
        width: 200px;
        // background: hsl(0, 0%, 70);
        background-color: rgb(192, 185, 185);
        backdrop-filter: blur(1rem);
        display: inline-flex;
        flex-direction: column;
        gap: 0.5rem;
        transition: all 0.5s ease;
        margin-left: -100%;
        transition: all 0.5s ease;

        @media screen and (max-width: 1046px) {
            position: fixed;
            inset: 0 0 0 0;
            width: 200px;
            // background: hsl(0, 0%, 70);
            background-color: rgb(192, 185, 185);
            backdrop-filter: blur(1rem);
            display: inline-flex;
            flex-direction: column;
            gap: 0.5rem;
            transition: all 0.5s ease;
            margin-left: -100%;
        }

        .logo{
            display: none;
            
            img{
                width: 100px;
                height: 100px;
                border-radius: 50px;
                margin: 0.5rem auto;
                margin-top: 1.5rem;
            }
            @media screen and (max-width: 1046px) {
                display: block;
            }
        }
        .menus{
            list-style-type: none;
            position: relative;
            cursor: pointer;
            float: left;
            width: 100px;
            height: 50px;
            line-height: 50px;

            @media screen and (max-width: 1046px) {
                
            }
            .icons{
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 1046px) {
                    float: left;
                }

                span{
                    min-width: max-content;
                }

                .icon{
                    font-size: larger;
                }
            }

            &:hover{
                color: blue;

                ol{
                    display: block;

                    @media screen and (max-width: 1046px) {
                        z-index: 1;
                    }
                }
            }

            ol{
                position: absolute;
                top: 100%;
                left: -20%;
                width: 200%;
                list-style: none;
                padding: 0;
                display: none;
                background-color: white;
                box-shadow: 0px 0px 10px 5px rgba(201, 201, 201, 0.47);
                padding: 5px 20px;
                border-radius: 10px;
                color: black;

                @media screen and (max-width: 1046px) {
                    width: max-content;
                }
                
                li{
                    min-width: 100%;

                    &:hover{
                        color: blue;
                        perspective: 1000px;;
                    }
                }
            }

            select{
                border: none;
                font-size: 1rem;
            }
            
            a{
                text-decoration: none;
            }
        }
    }


    .ulclose1{
        position: fixed;
        inset: 0 0 0 0;
        width: 200px;
        // background: hsl(0, 0%, 70);
        background-color: rgb(192, 185, 185);
        backdrop-filter: blur(1rem);
        display: inline-flex;
        flex-direction: column;
        gap: 0.5rem;
        transition: all 0.5s ease;
        // margin-left: -100%;
        transition: all 0.5s ease;

        @media screen and (max-width: 1046px) {
            position: fixed;
            inset: 0 0 0 0;
            width: 200px;
            // background: hsl(0, 0%, 70);
            background-color: rgb(192, 185, 185);
            backdrop-filter: blur(1rem);
            display: inline-flex;
            flex-direction: column;
            gap: 0.5rem;
            transition: all 0.5s ease;
            margin-left: -100%;
        }

        .logo{
            // display: none;
            
            img{
                width: 100px;
                height: 100px;
                border-radius: 50px;
                margin: 0.5rem auto;
                margin-top: 1.5rem;
            }
            @media screen and (max-width: 1046px) {
                display: block;
            }
        }
        .menus{
            list-style-type: none;
            position: relative;
            cursor: pointer;
            float: left;
            width: 100px;
            height: 50px;
            line-height: 50px;

            @media screen and (max-width: 1046px) {
                
            }
            .icons{
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 1046px) {
                    float: left;
                }

                span{
                    min-width: max-content;
                }

                .icon{
                    font-size: larger;
                }
            }

            &:hover{
                color: blue;

                ol{
                    display: block;

                    @media screen and (max-width: 1046px) {
                        z-index: 1;
                    }
                }
            }

            ol{
                position: absolute;
                top: 100%;
                left: -20%;
                width: 200%;
                list-style: none;
                padding: 0;
                display: none;
                background-color: white;
                box-shadow: 0px 0px 10px 5px rgba(201, 201, 201, 0.47);
                padding: 5px 20px;
                border-radius: 10px;
                color: black;

                @media screen and (max-width: 1046px) {
                    width: max-content;
                }
                
                li{
                    min-width: 100%;

                    &:hover{
                        color: blue;
                        perspective: 1000px;;
                    }
                }
            }

            select{
                border: none;
                font-size: 1rem;
            }
            
            a{
                text-decoration: none;
            }
        }
    }


    .account{
        display: flex;
        justify-content: space-between;
        margin-right: 30px;
        align-items: center;

        div{
            min-width: max-content;
        }
        
        a{
            margin-left: 20px;
            text-decoration: none;
        }

        .signin{
            font-weight: bold;
            color: black;

            @media screen and (max-width: 480px) {
                display: none;
            }

            &:hover{
                color: blue;
            }
        }

        .create{
            background-color: blue;
            padding: 13px 35px;
            border-radius: 30px;
            color: white;
            font-weight: bold;

            @media screen and (max-width: 1046px){
                padding: 10px 25px;
                min-width: max-content;
                margin-right: 2%;
            }
            @media screen and (max-width: 480px) {
                display: none;
            }

            &:hover{
                background-color: darkblue;
            }
        }
        .hamburger{
            z-index: 999;
            width: 3.5rem;
            height: 3.5rem;
            cursor: pointer;
            transition: all 0.5s ease;
            padding-right: 1rem;

            @media screen and (max-width: 1046px) {
                display: block;
            }
        }
    }
}