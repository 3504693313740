.card {
  width: calc(82% / 3);
  padding: 22px;
  background-color: #fff;
  border-radius: 20px;
  height: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  transition: all 0.3s ease-in-out;

  .img_wrapper {
    // height: 40%;
    width: 25%;
    background-color: #0e2038;
    border-radius: 10px;

    img {
      width: 100%;
      //   background: burlywood;
    }
  }

  .content {
    // background-color: #0e2038;
    padding-top: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    // border-radius: 20px;
    color: #000;
    height: 60%;
    width: 100%;
    text-align: left !important;

    h2 {
      text-align: left !important;
      font-size: 28px;
      font-weight: 800;
    }

    h3 {
      margin-bottom: 30pxf0f0f0;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 25px;
      color: goldenrod;
    }
    p {
      color: rgb(71, 69, 69);
      font-size: 18px;
    }
  }

  // @media screen and (max-width: 1046px) {
  //   width: 85%;

  //   // h1 {
  //   //   font-size: 1.5rem;
  //   // }
  // }
}

.card:hover {
  transform: translateY(-20px);
  box-shadow: none;

  // background-color: #0e2038;
  // color: #fff;

  // .img_wrapper {
  //   background-color: #fff;
  // }

  // .content {
  //   color: #fff;

  //   h2 {
  //     text-align: left !important;
  //     font-size: 28px;
  //     font-weight: 800;
  //   }

  //   p {
  //     color: #ccc;
  //     font-size: 18px;
  //   }
  // }
}
