.hero {
  // overflow-x: hidden;
  padding: 0;
  margin: 0;
  background-image: linear-gradient(to top, transparent, #fff),
    url("../../assets/images/hero-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;

  //   .shape1 {
  //     position: absolute;
  //     left: 5rem;
  //   }
  //   .shape2 {
  //     position: absolute;
  //     right: 5rem;
  //   }
  //   .shape3 {
  //     position: absolute;
  //     left: 15rem;
  //     top: 25rem;
  //   }
  //   .shape4 {
  //     position: absolute;
  //     top: 6rem;
  //   }
  //   .shape5 {
  //     position: absolute;
  //     top: 25rem;
  //     right: 10rem;
  //   }

  .wrapper {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 250px 0;

    .link {
      position: relative;
      width: 100%;
      height: 100px;
      animation: slideIn 1.5s ease-in-out;
      margin-bottom: 30px;

      .demo {
        position: absolute;
        bottom: 20%;
        right: 34%;
        background-color: green;
        color: white;
        font-size: 18px;
        text-decoration: none;
        padding: 15px 35px;
        border-radius: 30px;
        font-weight: bold;
        letter-spacing: 1px;
        cursor: pointer;

        &:hover {
          background-color: rgb(5, 73, 5);
        }
      }
    }
    // .container {
    //   width: 100%;
    //   height: 650px;
    //   display: flex;
    //   gap: 50px;
    //   align-items: stretch;

    //   .left,
    //   .right {
    //     width: 50%;
    //     overflow: hidden;
    //     position: relative;
    //   }

    //   .left {
    //     text-align: left;
    //     // padding: 120px 0;

    //     h1 {
    //       color: #0e2038;
    //       font-size: 84px;
    //       line-height: 1;
    //       font-weight: bolder;
    //       position: absolute;
    //       top: 0;
    //       animation: slideIn 1s ease-in-out;
    //     }

    //     p {
    //       font-size: 40px;
    //       top: 35%;
    //       color: #000306;
    //       font-weight: bolder;
    //       position: absolute;
    //       animation: slideIn 1.3s ease-in-out;
    //     }

    //     .link {
    //       position: relative;
    //       top: 80%;
    //       animation: slideIn 1.5s ease-in-out;

    //       .demo {
    //         position: absolute;
    //         background-color: green;
    //         color: white;
    //         font-size: 18px;
    //         text-decoration: none;
    //         padding: 15px 35px;
    //         border-radius: 30px;
    //         font-weight: bold;
    //         letter-spacing: 1px;
    //         // transition: all 0.5s ease;
    //         cursor: pointer;

    //         &:hover {
    //           background-color: rgb(5, 73, 5);
    //         }
    //       }
    //     }

    @keyframes slideIn {
      0% {
        left: -50%;
      }
      100% {
        left: 0%;
      }
    }
    //     @media screen and (min-width: 2280px) {
    //       .left {
    //         width: 60%;
    //       }
    //       h1 {
    //         top: 15%;
    //         font-size: 98x;
    //       }
    //       p {
    //         top: 40%;
    //         font-size: 32x;
    //         font-weight: normal;
    //         line-height: 42px;
    //       }
    //       .link {
    //         top: 67%;
    //       }
    //     }
    //     @media screen and (max-width: 2280px) and (min-width: 1647px) {
    //       h1 {
    //         font-size: 98px;
    //         font-weight: 900;
    //         top: 15%;
    //       }
    //       p {
    //         top: 50%;
    //         font-size: 36px;
    //         line-height: 45px;
    //       }
    //       .link {
    //         top: 77%;
    //       }
    //     }
    //     @media screen and (max-width: 1647px) and (min-width: 1291px) {
    //       h1 {
    //         font-size: 78px;
    //         top: 13%;
    //       }
    //       p {
    //         font-size: 32px;
    //         top: 40%;
    //       }
    //       .link {
    //         top: 66%;
    //       }
    //     }
    //     @media screen and (max-width: 1290px) and (min-width: 1200px) {
    //       h1 {
    //         font-size: 75px;
    //         top: 17%;
    //         line-height: 1;
    //       }
    //       p {
    //         font-size: 32px;
    //         top: 44%;
    //       }
    //       .link {
    //         top: 70%;
    //       }
    //     }
    //     @media screen and (max-width: 1200px) and (min-width: 1024px) {
    //       h1 {
    //         font-size: 70px;
    //         top: 17%;
    //       }
    //       p {
    //         font-size: 32px;
    //         top: 45%;
    //       }
    //       .link {
    //         top: 75%;
    //         .demo {
    //           padding: 12px 28px;
    //         }
    //       }
    //     }

    //     @media screen and (max-width: 764px) {
    //       h1 {
    //         text-align: center;
    //         font-size: 70px;
    //         top: 10%;
    //       }
    //       p {
    //         font-size: 22px;

    //         top: 45%;
    //       }
    //       .link {
    //         top: 75%;
    //         .demo {
    //           padding: 12px 28px;
    //         }
    //       }
    //     }
    //   }
    //   .right {
    //     height: 100%;
    //     // position: relative;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: contain;
    //       top: 0;
    //       position: absolute;
    //       animation: slideLeft 1s ease-in-out;
    //     }

    @keyframes slideLeft {
      0% {
        right: -50%;
      }
      100% {
        right: 0%;
      }
    }
    //   }
    //   @media screen and (min-width: 2280px) {
    //     .left {
    //       width: 65%;
    //     }
    //   }
    // }
    // .mobileContainer {
    //   display: none;
    // }
    // CONTAINER
    // @media screen and (min-width: 2280px) {
    //   .container {
    //     padding: 0;
    //   }
    // }
    // @media screen and (max-width: 2280px) {
    //   .container {
    //     padding: 0;
    //   }
    // }

    // @media screen and (max-width: 1124px) {
    //   .container {
    //     display: none;
    //   }
    //   .mobileContainer {
    //     width: 100%;
    //     display: block;
    //     height: 100vh;
    //     padding-top: 60px;

    //     .left {
    //       text-align: center;
    //       margin-bottom: 62px;

    //       h1 {
    //         font-size: 72px;
    //         font-weight: 800;
    //         margin-bottom: 20px;
    //       }

    //       p {
    //         font-size: 32px;
    //         margin-bottom: 20px;
    //       }

    //       .link {
    //         position: relative;
    //         width: 100%;
    //         height: 100px;
    //         animation: slideIn 1.5s ease-in-out;
    //         margin-bottom: 30px;

    //         .demo {
    //           position: absolute;
    //           bottom: 20%;
    //           right: 39%;
    //           background-color: green;
    //           color: white;
    //           font-size: 18px;
    //           text-decoration: none;
    //           padding: 15px 35px;
    //           border-radius: 30px;
    //           font-weight: bold;
    //           letter-spacing: 1px;
    //           cursor: pointer;

    //           &:hover {
    //             background-color: rgb(5, 73, 5);
    //           }
    //         }
    //       }
    //     }
    //     .right {
    //       display: none;
    //     }
    //   }
    // }
    // @media screen and (max-width: 1024px) {
    //   .mobileContainer {
    //     padding-top: 0;

    //     .left {
    //       h1 {
    //         margin-bottom: 0;
    //       }
    //       p {
    //         margin-bottom: 0px;
    //       }
    //       .link {
    //         .demo {
    //           right: 38%;
    //         }
    //       }
    //     }
    //     .right {
    //       display: none;
    //     }
    //   }
    // }

    @media screen and (min-width: 1023px) and (max-width: 1270px) and (min-height: 599px) and (max-height: 789px) {
      .mobileContainer {
        display: none;
      }
      .container {
        width: 100%;
        height: 315px;
        display: flex;
        gap: 50px;
        align-items: stretch;

        .left,
        .right {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: relative;
        }
        .left {
          h1 {
            color: #0e2038;
            line-height: 1;
            position: absolute;
            top: 0;
            font-size: 38px;
            margin-top: 55px;
            font-weight: 800;
            animation: slideIn 1s ease-in-out;
          }
          p {
            margin-top: 20px;
            top: 35%;
            color: #000306;
            position: absolute;
            animation: slideIn 1.3s ease-in-out;
          }
          .link {
            position: relative;
            width: 100%;
            height: 100px;
            animation: slideIn 1.5s ease-in-out;
            margin-bottom: 30px;
            top: 60%;

            .demo {
              width: 170px;
              bottom: 20%;
              left: 0%;
            }
          }
        }
        .right {
          div {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1271px) and (min-height: 790px) {
      .mobileContainer {
        display: none;
      }
      .container {
        display: none;
        width: 100%;
        height: 550px;
        display: flex;
        gap: 50px;
        align-items: stretch;

        .left,
        .right {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: relative;
        }
        .left {
          h1 {
            color: #0e2038;
            line-height: 1;
            position: absolute;
            top: 15%;
            font-size: 68px;
            margin-bottom: 25px;
            font-weight: 800;
            animation: slideIn 1s ease-in-out;
          }
          p {
            margin-top: 18px;
            font-size: 28px;
            top: 40%;
            line-height: 1;
            color: #000306;
            position: absolute;
            animation: slideIn 1.3s ease-in-out;
          }
          .link {
            position: relative;
            width: 100%;
            height: 100px;
            animation: slideIn 1.5s ease-in-out;
            margin-bottom: 30px;
            top: 60%;

            .demo {
              width: 170px;
              bottom: 20%;
              left: 0%;
            }
          }
        }
        .right {
          div {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1271px) and (max-height: 790px) {
      .mobileContainer {
        display: none;
      }
      .container {
        display: none;
        width: 100%;
        height: 550px;
        display: flex;
        gap: 50px;
        align-items: stretch;

        .left,
        .right {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: relative;
        }
        .left {
          h1 {
            color: #0e2038;
            line-height: 1;
            position: absolute;
            top: 15%;
            font-size: 68px;
            margin-bottom: 25px;
            font-weight: 800;
            animation: slideIn 1s ease-in-out;
          }
          p {
            margin-top: 18px;
            font-size: 28px;
            top: 40%;
            line-height: 1;
            color: #000306;
            position: absolute;
            animation: slideIn 1.3s ease-in-out;
          }
          .link {
            position: relative;
            width: 100%;
            height: 100px;
            animation: slideIn 1.5s ease-in-out;
            margin-bottom: 30px;
            top: 60%;

            .demo {
              width: 170px;
              bottom: 20%;
              left: 0%;
            }
          }
        }
        .right {
          div {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1022px) and (min-height: 933px) and (max-height: 1300px) {
      .container {
        display: none;
      }
      .mobileContainer {
        width: 100%;
        display: block;
        height: 100vh;
        flex-direction: column !important;

        .mobile {
          width: 100%;
          height: 100%;
          padding: 60px 0;
          .left {
            text-align: center;
            margin-bottom: 62px;

            h1 {
              font-size: 58px !important;
              line-height: 1;
              font-weight: 800;
              margin-bottom: 20px;
            }

            p {
              font-size: 26px;
              margin-bottom: 20px;
            }

            .link {
              position: relative;
              width: 100%;
              height: 100px;
              animation: slideIn 1.5s ease-in-out;
              margin-bottom: 30px;

              .demo {
                position: absolute;
                bottom: 20%;
                right: 34%;
                background-color: green;
                color: white;
                font-size: 18px;
                text-decoration: none;
                padding: 15px 35px;
                border-radius: 30px;
                font-weight: bold;
                letter-spacing: 1px;
                cursor: pointer;

                &:hover {
                  background-color: rgb(5, 73, 5);
                }
              }
            }
          }
          .right {
            img {
              width: 100%;
              height: 80%;
              // margin-bottom: 180px;
            }
          }
        }
      }
    }

    // TABLE DEVICES
    @media screen and (max-width: 1040px) and (min-height: 869px) and (max-height: 1300px) {
      .container {
        display: none;
      }
      .mobileContainer {
        width: 100%;
        display: block;
        height: 100vh;
        flex-direction: column !important;

        .mobile {
          width: 100%;
          height: 100%;
          padding: 60px 0;
          .left {
            text-align: center;
            margin-bottom: 62px;

            h1 {
              font-size: 58px !important;
              line-height: 1;
              font-weight: 800;
              margin-bottom: 20px;
            }

            p {
              font-size: 26px;
              margin-bottom: 20px;
            }

            .link {
              position: relative;
              width: 100%;
              height: 100px;
              animation: slideIn 1.5s ease-in-out;
              margin-bottom: 30px;

              .demo {
                position: absolute;
                bottom: 20%;
                right: 34%;
                background-color: green;
                color: white;
                font-size: 18px;
                text-decoration: none;
                padding: 15px 35px;
                border-radius: 30px;
                font-weight: bold;
                letter-spacing: 1px;
                cursor: pointer;

                &:hover {
                  background-color: rgb(5, 73, 5);
                }
              }
            }
          }
          .right {
            img {
              width: 100%;
              height: 80%;
              // margin-bottom: 180px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .container {
        display: none;
      }
      .mobileContainer {
        .left {
          text-align: center;
          margin-bottom: 0;

          h1 {
            font-size: 52px;
            font-weight: 800;
          }

          p {
            font-size: 32px;
            margin-bottom: 30px;
          }

          .link {
            margin-bottom: 0;
            .demo {
              width: 38%;
              margin: auto;
              bottom: 30%;
              left: 25%;
            }
          }
        }
      }
    }
    // MOBILE DEVICES
    @media screen and (max-width: 468px) and (max-height: 932px) and (min-height: 573px) {
      .container {
        display: none;
      }
      .mobileContainer {
        .left {
          text-align: center;
          margin-bottom: 62px;

          h1 {
            font-size: 48px;
            font-weight: 800;
            margin-bottom: 42px;
          }

          p {
            font-size: 26px;
            margin-bottom: 35px;
          }

          .link {
            .demo {
              width: 76%;
              bottom: 20%;
              left: 0%;
            }
          }
        }
        .right {
          display: none;
        }
      }
    }
    // SMALLER MOBILE DEVICES
    @media screen and (max-width: 320px) and (max-height: 572px) {
      .container {
        display: none;
      }
      .mobileContainer {
        .left {
          text-align: center;
          margin-bottom: 62px;

          h1 {
            font-size: 36px;
            font-weight: 800;
          }

          p {
            font-size: 22px;
            margin-bottom: 18px;
            line-height: 22px;
          }

          .link {
            .demo {
              width: 76%;
              bottom: 20%;
              left: 0%;
              padding: 10px 30px;
            }
          }
        }
        .right {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 2024px) and (min-width: 1024px) {
    .wrapper {
      width: 90%;
    }
  }
  @media screen and (max-width: 1824px) {
    .wrapper {
      padding: 80px 0;
    }
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      padding: 150px 0;
    }
  }
  @media screen and (max-width: 320px) {
    .wrapper {
      padding: 100px 0;
    }
  }
}
