.navContainer {
  width: 100%;
  .nav {
    width: 100%;
    // padding: 0 20px;
    // background-color: white;
    // box-shadow: 0px 0px 20px 0px rgba(105, 105, 105, 0.47);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 4rem;
    position: fixed;
    top: 0;
    z-index: 999;

    @media screen and (max-width: 500px) {
      // padding: 0 1rem;
    }
    @media screen and (max-width: 1024px) {
      // padding: 0 2rem;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .ul {
      display: flex;

      li {
        list-style: none;
        margin-left: 3rem;
        cursor: pointer;

        a {
          font-size: 1.3rem;
          font-weight: 600;
          text-decoration: none;
          color: black;

          &:hover {
            color: blue;
          }
        }
      }
    }
    .ul-2 {
      display: flex;
      padding-top: 75px;

      li {
        list-style: none;
        padding-left: 3rem;

        a {
          font-size: 1.3rem;
          font-weight: 600;
          text-decoration: none;
          color: black;

          &:hover {
            color: blue;
          }
        }
      }
    }
    .phone {
      display: flex;
      gap: 1rem;
      background-color: green;
      color: white;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      padding: 0.7rem 1rem;
      border-radius: 50px;
    }
    .phone:hover {
      background-color: rgb(5, 73, 5);
    }
    .menu-icon {
      z-index: 999;
      display: none;
    }
    @media screen and (max-width: 1024px) {
      img {
        width: 70px;
        height: 70px;
        margin: 0.2rem 0.2rem;
      }
      .ul {
        li {
          margin-left: 2rem;
        }
      }
      .phone {
        gap: 0.5rem;
        color: white;
        padding: 0.5rem;
        border-radius: 50px;
        min-width: max-content;
      }
    }
    @media screen and (max-width: 820px) {
      img {
        width: 75px;
        height: 75px;
      }
      .ul {
        flex-direction: column;
        background-color: gray;
        position: fixed;
        right: -1000px;
        top: 0;
        height: 100vh;
        width: 50%;
        transition: all 0.5s ease-in-out;

        li {
          padding: 1rem;
          margin-left: -2rem;
          font-size: 1.5rem;
          // border: 1px solid rgb(184, 184, 184);
          margin-bottom: 5px;
        }
        .hme {
          margin-top: 0.5rem;
        }
      }
      .phone {
        display: none;
      }
      .ul-2 {
        flex-direction: column;
        background-color: white;
        // background-color: rgb(210, 210, 210);
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 50%;
        transition: all 0.5s ease-in-out;

        li {
          padding: 1rem;
          margin-left: -2rem;
          font-size: 1.5rem;
          // border: 1px solid rgb(184, 184, 184);
          border-radius: 10px;
          margin-bottom: 5px;

          &:hover {
            background-color: gray;
            cursor: pointer;

            a {
              color: #fff;
            }
          }
        }
        .hme {
          margin-top: 0.5rem;
        }
      }
      // .phone {
      //   display: none;
      // }
      .menu-icon {
        display: block;
        font-size: 2rem;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 500px) {
      img {
        width: 75px;
        height: 75px;
      }
      .ul {
        flex-direction: column;
        background-color: gray;
        position: fixed;
        right: -1000px;
        top: 0;
        height: 100vh;
        width: 50%;
        transition: all 0.5s ease-in-out;

        li {
          padding: 1rem;
          margin-left: -2rem;
          font-size: 1.5rem;
          border: 1px solid rgb(184, 184, 184);
          margin-bottom: 5px;
        }
        .hme {
          margin-top: 0.5rem;
        }

        .phone {
          Link {
            font-size: 12px;
          }
        }
      }
      .ul-2 {
        flex-direction: column;
        background-color: rgb(210, 210, 210);
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 50%;
        transition: all 0.5s ease-in-out;

        li {
          padding: 1rem;
          margin-left: -2rem;
          font-size: 1.5rem;
          border: 1px solid rgb(184, 184, 184);
          margin-bottom: 5px;

          &:hover {
            background-color: gray;
            cursor: pointer;
          }
          a {
            &:hover {
              color: black;
            }
          }
        }
        .hme {
          margin-top: 0.5rem;
        }
      }

      .menu-icon {
        display: block;
        font-size: 3rem;
        cursor: pointer;
      }
    }
  }
}
.scroll_bg {
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(105, 105, 105, 0.47);
}
.active {
  color: blue !important;
}
